

// -----------------------------------------------------------------------------------------*/
// MAIN APP --------------------------------------------------------------------------------*/
// -----------------------------------------------------------------------------------------*/
.main-app { 
    position: relative;
    padding-top: $header-height-mobile;

    @include media-breakpoint-up($app-breakpoint) {
        padding-top: $header-height-desktop;
    }
}


// -----------------------------------------------------------------------------------------*/
// MAIN APP CONTAINER ----------------------------------------------------------------------*/
// -----------------------------------------------------------------------------------------*/
.main-app-container { 
    position: relative;
    text-align: center;
    overflow-y: auto;
    min-height: calc( 100vh - #{$header-height-mobile + $timeline-height-mobile});
    padding-top: 1.8rem;
    padding-bottom: 10rem;
    > .container { 
        max-width: 1040px;
        position: relative;
        z-index: 1;
    }
    form {
        text-align: left;
    }
    .form-floating { 
        width: 100%;
        max-width: 600px;
    }

    @include media-breakpoint-up($app-breakpoint) {
        padding-top: 3rem;
        min-height: calc( 100vh - #{$header-height-desktop + $timeline-height-desktop});
        .form-floating { 
            width: auto;
        }
    }
}

.main-app-title {
    max-width: 700px;
    display: inline-block;
    margin-bottom: 1.6rem;
}

.main-app-icon { 
    background: rgba($primary, 0.1);
    height: 100px;
    width: 100px;
    border-radius: 50%;
    font-size: 4rem;
    color: $primary;

    @include media-breakpoint-up($app-breakpoint) {
        height: 120px;
        width: 120px;
        font-size: 4.2rem;
    }
}



.main-app-text { 
    font-style: italic;
    @extend .text-muted;
    margin-bottom: 1.2rem;
}


// -----------------------------------------------------------------------------------------*/
// QUESTION --------------------------------------------------------------------------------*/
// -----------------------------------------------------------------------------------------*/
.box-question { 
    text-align: left;
    max-width: 600px;
    > div {
        background-color: $bg-dialogue;
        padding: 18px 24px;
        position: relative;
        border-radius: 0 1.2rem 1.2rem 1.2rem;
        &::before { 
            content: "";
            display: block;
            width: 1px;
            height: 1px;
            border: 16px solid transparent;
            border-top-color: $bg-dialogue;
            position: absolute;
            left: -16px;
            top: 0;
        }
    }

    @include media-breakpoint-up($app-breakpoint) {
        > img {
            width: 80px;
            height: 80px;
        }
        > div {
            padding: 20px 30px;
            &::before { 
                border-width: 28px;
                left: -28px;
            }
        }
    }
}